// api/news.ts
import axios from "./base";
import { News, NewsQueryBody, NewsResponse } from "./types/news";

// Get a single news item by ID
export const getNewsById = async (id: string): Promise<News> => {
    const response = await axios.get<{ news: News }>(`/news/${id}`);
    return response.data.news;
};

// Query news items
export const getAllNews = async (query: NewsQueryBody): Promise<NewsResponse> => {
    const response = await axios.post<NewsResponse>("/news/query", query);
    return response.data;
};

// Create a news item
export const createNews = async (news: Partial<News>): Promise<News> => {
    const response = await axios.post<{ news: News }>("/news", news);
    return response.data.news;
};

// Update a news item
export const updateNews = async (id: string, news: Partial<News>): Promise<News> => {
    const response = await axios.put<{ news: News }>(`/news/${id}`, news);
    return response.data.news;
};

export const uploadNewsBodyImage = async (url: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
        await axios.request({
            method: "POST",
            url: `https://new.999111.ru/admin/api/v1${url}`,
            data: file,
            headers: {
                "Content-Type": file.type,
            },
        });
    } catch (error) {
        console.error("Failed to upload image:", error);
        throw error;
    }
};

