import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) {
    // If no access_token, redirect to login
    return <Navigate to="/login" />;
  }

  // If access_token exists, allow access to the protected route
  return children;
};

export default PrivateRoute;
