import axiosInstance from './base';
import {
  GetUserByPhoneResponse,
  GetUserByIdResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  VerifyPhoneRequest,
  VerifyPhoneResponse,
  VerifyPhoneCompleteRequest,
} from './types/user';

// Function to get user by phone
export const getUserByPhone = async (phone: string): Promise<GetUserByPhoneResponse> => {
  const response = await axiosInstance.post<GetUserByPhoneResponse>('/users/get-by-phone', { phone });
  return response.data;
};

// Function to get user by ID
export const getUserById = async (id: string): Promise<GetUserByIdResponse> => {
  const response = await axiosInstance.post<GetUserByIdResponse>('/users/get-by-id', { id });
  return response.data;
};

// Function to register user
export const registerUser = async (user: RegisterUserRequest): Promise<RegisterUserResponse> => {
  const response = await axiosInstance.post<RegisterUserResponse>('/users/register', user);
  return response.data;
};

// Function to verify phone
export const verifyPhone = async (phone: string): Promise<VerifyPhoneResponse> => {
  const response = await axiosInstance.post<VerifyPhoneResponse>('/users/verify-phone', { phone });
  return response.data;
};

// Function to complete phone verification
export const verifyPhoneComplete = async (attempt_id: string, code: number): Promise<void> => {
  await axiosInstance.post('/users/verify-phone-complete', { attempt_id, code });
};
