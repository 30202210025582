import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "@ckeditor/ckeditor5-core";
import {
  UploadAdapter,
  FileLoader,
} from "@ckeditor/ckeditor5-upload/src/filerepository";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import './ckeditor-custom-styles.css';

export const RTE = ({
  content,
  setContent,
  uploadImageUrl
}: {
  content: string;
  setContent: (content: string) => void;
  uploadImageUrl: string
}) => {
  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            console.log("here is the file", file);
            if (!file) {
              return reject("File is null");
            }

            const response = await axios.request({
              method: "POST",
              url: uploadImageUrl,
              data: file,
              headers: {
                "Content-Type": file.type,
              },
            });

            console.log(response);
            resolve({
              default: `${response.data.url}`,
            });
          } catch (error) {
            reject(error);
          }
        });
      },
      abort: () => { },
    };
  }

  function uploadPlugin(editor: Editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      editor={ClassicEditor}
      data={content}
      config={{ extraPlugins: [uploadPlugin] }}
      onChange={(event, editor) => {
        setContent(editor.getData());
      }}
    />
  );
};
