import React from "react";
import { Loader, Table } from "@mantine/core";

interface TablePatternProps {
  filters?: React.ReactNode;
  headers: string[];
  data: any[];
  getFieldToStyle?: () => string;
  getStyle?: (value: any) => React.CSSProperties;
  onRowClick?: (data: any) => void;
  customDataType?: string;
  customData?: any;
  loading?: boolean;
  activePage: number;
  selectedRow: string;
}

const TablePattern: React.FC<TablePatternProps> = (props) => {
  const handleRowClick = (row: any, index: number) => {
    if (props.onRowClick) {
      switch (props.customDataType) {
        case "banner":
        case "stores":
        case "employee":
        case "article":
        case "collection":
        case "promotion":
        case "review":
        case "edo":
        case "orders":
          props.onRowClick(props.customData[index]);
          break;
        default:
          props.onRowClick(row);
      }
    }
  };

  const rows =
    props.data.length > 0
      ? props.data.map((element, index) => (
          <Table.Tr key={index} onClick={() => handleRowClick(element, index)}>
            {Object.keys(element).map((key) => (
              <Table.Td key={key}>
                {(key === "tag" && props.customDataType !== "abandonedCart") ||
                (key === "category" && props.customDataType === "promoCode") ? (
                  <span
                    style={{
                      borderRadius: 16,
                      padding: "8px 16px",
                      fontWeight: 500,
                      border: "1px solid #E0E0E0",
                    }}
                  >
                    {element[key]}
                  </span>
                ) : key.includes(
                    props.getFieldToStyle ? props.getFieldToStyle() : ""
                  ) ? (
                  <span
                    style={props.getStyle ? props.getStyle(element[key]) : {}}
                  >
                    {element[key]}
                  </span>
                ) : element[key].includes("png") ? (
                  <img
                    src={element[key]}
                    alt=""
                    style={{ height: "50px", width: "auto" }}
                  />
                ) : (
                  element[key]
                )}
              </Table.Td>
            ))}
          </Table.Tr>
        ))
      : null;

  return (
    <div>
      <div
        style={{ marginBottom: "1rem", display: "flex", gap: "16px" }}
        className="filter"
      >
        {props.filters}
      </div>
      {props.loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <>
          <Table withTableBorder verticalSpacing="md">
            <Table.Thead
              style={{ color: "#1E2134", backgroundColor: "#FAFAFA" }}
            >
              <Table.Tr>
                {props.headers.map((header, index) => (
                  <Table.Th key={index}>{header}</Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default TablePattern;
