import axios from "./base";
import { Banners, GetBannersParams, BannerFormData } from "./types/banners";

export const getBanners = async (
  params: GetBannersParams
): Promise<{ banners: Banners[]; total: number }> => {
  const response = await axios.post("/banners/query", params);
  return response.data;
};

export const getBannerById = async (id: string): Promise<Banners> => {
  const response = await axios.get(`/banners/${id}`);
  return response.data.banner;
};

export const createBanner = async (banner: BannerFormData) => {
  const response = await axios.post("/banners", banner);
  return response.data;
};

export const updateBanner = async (id: string, banner: BannerFormData) => {
  const response = await axios.put(`/banners/${id}`, banner);
  return response.data;
};

export const uploadImage = async (url: string, file: File | null) => {
  const formData = new FormData();

  if (file === null) {
    formData.append("file", "null");
  } else {
    formData.append("file", file);
  }

  try {
    await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("Failed to upload image:", error);
    throw error;
  }
};
