import axios from "./base";
import { GetOrderByIdResponse, OrderResponse } from "./types/orders";

export const fetchOrders = async (params: {
  search: string;
  statuses: string[] | null,
  payment_methods: string[] | null,
  receive_types: string[] | null,
  date_from: string | null,
  date_to: string | null,
  offset: number;
  limit: number;
}): Promise<OrderResponse> => {
  const response = await axios.post("/orders/query", params);
  return response.data;
};

export const getOrderById = async (orderNumber:number): Promise<GetOrderByIdResponse> =>{
  const response = await axios.get(`/orders/${orderNumber}`);
  return response.data;
}