import React, { useState } from "react";
import {
  Group,
  TextInput,
  Radio,
  Checkbox,
  Button,
  Select,
} from "@mantine/core";
import { Search } from "tabler-icons-react";

const DeliveryComponent: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const handleResetFilters = () => {
    setSearchValue("");
  };
  return (
    <div className="gap-4 flex flex-col">
      <h2 className="basefont-h2 mt-4 mb-4">Адрес доставки</h2>
      <div style={{ display: "flex" }}>
        <TextInput
          style={{ flex: 1, width: "1318px", backgroundColor: "white" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          placeholder="Поиск по названию магазина"
          leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
          size="md"
          radius="md"
        />
        <Button
          onClick={handleResetFilters}
          size="md"
          radius="sm"
          style={{
            backgroundColor: "white",
            color: "#25262B",
            borderRadius: 12,
            fontSize: 15,
            width: "120px",
          }}
        >
          Сбросить
        </Button>
      </div>
      <div style={{ display: "flex", gap: "24px", marginRight: "10px" }}>
        <TextInput
          style={{ width: "100%" }}
          placeholder="Введите номер подъезда"
          size="md"
          label="Подъезд"
          radius="md"
        />
        <TextInput
          style={{ width: "100%" }}
          placeholder="Оставьте комментарий курьеру"
          size="md"
          label="Комментарий курьеру"
          radius="md"
        />
      </div>
      <h2>Варианты доставки</h2>
      <Group>
        <Radio.Group>
          <Group
            mt="xs"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <Radio
              variant="outline"
              value="entrance"
              label="До подъезда (без подъема)"
            />
            <Radio variant="outline" value="floor" label="С подъемом на этаж" />
          </Group>
        </Radio.Group>
      </Group>

      <div style={{ display: "flex", gap: "24px", marginRight: "10px" }}>
        <TextInput
          style={{ width: "100%" }}
          placeholder="Введите номер этажа"
          size="md"
          label="Этаж"
          radius="md"
        />
        <TextInput
          style={{ width: "100%" }}
          placeholder="Введите номер квартиры"
          size="md"
          label="Квартира"
          radius="md"
        />
      </div>
      <Checkbox label="Есть лифт" />
      <h2 className="basefont-h2">Дата доставки</h2>
      <Select
        style={{ width: "648px" }}
        label="Дата доставки"
        data={["Выберите дату доставки"]}
        size="md"
        radius="md"
      />
      <div className="bg-[#99BEE4] w-full rounded-xl p-[8px] text-txt-primary">
        Стоимость доставки в пункт выдачи: 800 ₽
      </div>
    </div>
  );
};

export default DeliveryComponent;
