import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import {
  Collections,
  GetCollectionsParams,
} from "../../lib/api/types/collection";
import { getCollections } from "../../lib/api/collections";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import Paginator from "../../shared/paginator";

interface CollectionTableProps {
  setID: (id: string) => void;
  openModal: () => void;
  setModalTitle: (title: string) => void;
  openedSideBar: boolean;
}

const CollectionTable: React.FC<CollectionTableProps> = ({
  setID,
  openModal,
  setModalTitle,
  openedSideBar,
}) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [collections, setCollections] = useState<Collections[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchCollections = async (params: GetCollectionsParams) => {
    setLoading(true);
    const response = await getCollections(params);
    setCollections(response.collections);
    setTotal(response.total);
    setLoading(false);
  };
  useEffect(() => {
    const offset = (activePage - 1) * selectedRowsNumber;
    const activeStatus: boolean[] | null =
      selectedStatus?.includes("Все") || null
        ? [false, true]
        : selectedStatus?.map((v) => v === "Активен") || null;
    const params: GetCollectionsParams = {
      search: searchId,
      publish_from: dateValue ? dateValue[0]! : null,
      publish_to: dateValue ? dateValue[1]! : null,
      offset: offset,
      active: activeStatus,
      limit: selectedRowsNumber,
    };
    fetchCollections(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, dateValue, activePage, selectedRows, selectedStatus]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus(null);
    fetchCollections({
      search: "",
      publish_from: null,
      publish_to: null,
      offset: 0,
      limit: selectedRowsNumber,
      active: null,
    });
  };
  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus(["Все", "Активен", "Не активен"]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 3, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: openedSideBar ? `calc(100% - 5px)` :'' ,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не активен"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />
      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID",
    "Название подборки",
    "Дата создания",
    "Сроки публикации",
    "Статус активности",
  ];
  // Format data for table
  const formattedData = collections.map((collection) => ({
    id: collection.id,
    created: new Date(collection.created)
      .toLocaleDateString("ru-RU")
      .toString(),
    name: collection.name,
    valid:
      "c " +
      new Date(collection.publish_from).toLocaleDateString("ru-RU").toString() +
      " по " +
      new Date(collection.publish_to).toLocaleDateString("ru-RU").toString(),
    active: collection.active ? "Активен" : "Не активен",
  }));

  const getFieldToStyle = () => "active";

  const getStatusStyle = (status: string) => {
    return status === "Активен"
      ? {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        }
      : {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
  };

  const handleRowClick = (collection: Collections) => {
    setID(collection.id);
    openModal();
    setModalTitle("Редактирование подборки");
  };

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="collection"
          customData={collections}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default CollectionTable;
