import React, { useState } from "react";
import { Button, TextInput } from "@mantine/core";
import { Search } from "tabler-icons-react";

const StoreComponent: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const handleResetFilters = () => {
    setSearchValue("");
  };
  return (
    <div>
      <h2 className="basefont-h2 mb-8 mt-4">Выбор магазина для самовывоза</h2>
      <div style={{ display: "flex" }}>
        <TextInput
          style={{ flex: 1, width: "1318px" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          placeholder="Введите адрес пункта выдачи"
          leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
          size="md"
          radius="md"
        />
        <Button
          onClick={handleResetFilters}
          size="md"
          radius="sm"
          style={{
            backgroundColor: "white",
            color: "#25262B",
            borderRadius: 12,
            fontSize: 15,
            width: "120px",
          }}
        >
          Сбросить
        </Button>
      </div>
    </div>
  );
};

export default StoreComponent;
