import { Loader, Modal, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "../../shared/button";
import { useEffect, useState } from "react";
import { createAdmin, getAdminById, updateAdmin } from "../../lib/api/employee";

interface EmployeeFormValues {
  first_name: string;
  last_name: string;
  father_name: string;
  email: string;
  password: string;
  role: string;
  active: boolean;
  created: string;
}

interface EmployeeModalProps {
  opened: boolean;
  close: () => void;
  modalTitle: string;
  id: string | null;
  setUpdateData: (any: any) => void;
}

const roleMap: Record<string, string> = {
  super_admin: 'Aдминистратор',
  manager: 'Mенеджер ИМ',
  content_manager: 'Kонтент-менеджер',
};

const EmployeeModal: React.FC<EmployeeModalProps> = ({
  opened,
  close,
  modalTitle,
  id,
  setUpdateData
}) => {
  const fieldStyles = {};
  const form = useForm<EmployeeFormValues>({
    initialValues: {
      first_name: "",
      created: "",
      last_name: "",
      father_name: "",
      email: "",
      password: "",
      role: "",
      active: false,
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdmin = async () => {
      if (id) {
        setLoading(true);
        try {
          const admin = await getAdminById(id);
          form.setValues({
            first_name: admin.first_name,
            role: admin.role,
            last_name: admin.last_name,
            active: admin.active,
            created: admin.created,
            email: admin.email,
            father_name: admin.father_name
          });
        } catch (error) {
          console.error("Failed to fetch banner:", error);
        } finally {
          setLoading(false);
        }
      } else {
        form.reset();
        setLoading(false);
      }
    };
    fetchAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getStatusLabel = (status: boolean) =>
    status ? "Активен" : "Не Активен";

  const handleAdmin = async (values: EmployeeFormValues) => {
    try {
      if (id) {
        const updatedValues = {
          ...values,
          password: values.password.trim() === "" ? null : values.password,
        };
        await updateAdmin(id, updatedValues);
      } else {
        await createAdmin(values);
      }
      setUpdateData((prev: any) => !prev);
      form.reset();
      close();
    } catch (error) {
      console.error("Failed to add banner:", error);
    }
  }
  const handleSubmit = async () => {
    await handleAdmin(form.values);
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title={modalTitle}
      centered
      className="personal-model"
    >
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Имя"
            placeholder="Введите имя"
            style={{ padding: "12px" }}
            value={form.values.first_name}
            {...form.getInputProps("first_name")}
            styles={fieldStyles}
          />
          <TextInput
            label="Фамилия"
            placeholder="Введите фамилию"
            style={{ padding: "12px" }}
            value={form.values.last_name}
            {...form.getInputProps("last_name")}
            styles={fieldStyles}
          />
          <TextInput
            label="Отчество"
            placeholder="Введите отчество"
            style={{ padding: "12px" }}
            value={form.values.father_name}
            {...form.getInputProps("father_name")}
            styles={fieldStyles}
          />
          <TextInput
            label="Почта"
            placeholder="Введите почту"
            style={{ padding: "12px" }}
            {...form.getInputProps("email")}
            styles={fieldStyles}
          />

          <TextInput
            label={
              modalTitle === "Добавить сотрудника" ? "Пароль" : "Новый пароль"
            }
            placeholder={
              modalTitle === "Добавить сотрудника"
                ? "Введите пароль"
                : "Введите новый пароль"
            }
            style={{ padding: "12px" }}
            {...form.getInputProps("password")}
            value={form.values.password}
            styles={fieldStyles}
          />
          <Select
            label="Должность"
            placeholder="Выберите должность"
            size="md"
            radius="md"
            style={{ padding: "12px" }}
            value={roleMap[form.values.role]}
            data={["Aдминистратор", "Mенеджер ИМ", "Kонтент-менеджер"]}
            onChange={(value) => {
              const selectedType = Object.keys(roleMap).find((key) => roleMap[key] === value);
              form.setFieldValue("role", selectedType!);
            }}
          />

          {modalTitle !== "Добавить сотрудника" && (
            <Select
              label="Статус активности"
              placeholder="Выберите статус"
              data={["Активен", "Не Активен"]}
              style={{ padding: "12px" }}
              {...form.getInputProps("status")}
              value={getStatusLabel(form.values.active)}
              onChange={(value) =>
                form.setFieldValue("active", value === "Активен")
              }
              size="md"
              radius="md"

            />
          )}

          <div
            className="flex flex-col gap-2 mt-2"
            style={{
              justifyContent:
                modalTitle === "Добавить сотрудника" ? "center" : "space-between",
            }}
          >
            <Button
              type="submit"
              style={{
                backgroundColor: "#0560BE",
                width: "410px",
                height: "48px",
                padding: "8px, 20px, 8px, 20px",
                marginLeft: "10px",
              }}
            >
              {modalTitle === "Добавить сотрудника" ? "Добавить" : "Сохранить"}
            </Button>
            {modalTitle !== "Добавить сотрудника" && (
              <Button
                variant="outline"
                onClick={close}
                style={{
                  width: "410px",
                  height: "48px",
                  padding: "8px, 20px, 8px, 20px",
                  marginLeft: "10px",
                }}
              >
                Отменить
              </Button>
            )}
          </div>
        </form>
      )}
    </Modal>
  );
};

export default EmployeeModal;
